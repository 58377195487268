import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Layout, Menu, ConfigProvider, Card } from 'antd';
import { HomeOutlined, ShoppingCartOutlined } from '@ant-design/icons'; // Certifique-se de importar os ícones
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import CartPage from './pages/CartPage';
import SuccessPage from './pages/SuccessPage';
import { CartProvider } from './contexts/CartContext';
import './style.css';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import particlesConfig from './particlesConfig';
import FailurePage from './pages/FailurePage';

const { Header } = Layout;

function App() {
  // eslint-disable-next-line
  const [init, setInit] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [selectedKey, setSelectedKey] = useState('1'); // Estado para a chave selecionada

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };



  const handleMenuClick = (e) => {
    setSelectedKey(e.key); // Atualiza a chave selecionada ao clicar no menu
  };



  return (
    <ConfigProvider>
      <Router>
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={particlesConfig}
      />

<Card className="transparent-card" style={{ width: "100%" }}>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <img 
      src="https://i.imgur.com/fT1EtVr.png" 
      style={{ height: 70, objectFit: 'cover', marginRight: '20px' }} 
      alt="Logo" 
    />
    <h1 className="custom-title" style={{ marginTop: 20 }}>Os Perdidão</h1>
  </div>
</Card>

        <Header style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          backgroundColor: '#0A0A0A',
          opacity: 0.85,
          bottom: 0,
          marginLeft: -50
        }}>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'transparent' }}
          >
            <Menu.Item key="1" icon={<HomeOutlined style={{ fontSize: '20px', marginLeft: 10 }} />}>
              <Link to="/"></Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<ShoppingCartOutlined style={{ fontSize: '20px', marginLeft: 10 }} />}>
              <Link to="/cart"></Link>
            </Menu.Item>        
          </Menu>
        </Header>

        <div className="site-layout-content" style={{ marginTop: '64px' }}> {/* Margem para o Header fixo */}
          <CartProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/failure" element={<FailurePage />} />
           
            </Routes>
          </CartProvider>
        </div>
      </Router>
    </ConfigProvider>
  );
}

export default App;
