// LoginPage.js
import React, { useState } from 'react';
import { auth } from '../firebaseConfig'; // Importa a configuração do Firebase
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Form, Input, Button, message, Card } from 'antd';

const LoginPage = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      message.success('Login bem-sucedido!');
      onLogin(); // Chama a função passada como prop para indicar que o login foi bem-sucedido
    } catch (error) {
      message.error('Erro ao fazer login. Tente novamente.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{height: 170}}>
    <Form onFinish={onFinish} layout="vertical">
      <Form.Item name="email" rules={[{ required: true, message: 'Por favor, insira seu e-mail!' }]}>
        <Input variant="borderless" placeholder="E-mail" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}>
        <Input.Password style={{top: -15}} variant="borderless" placeholder="Senha" />
      </Form.Item>
      <Form.Item>
        <Button style={{top: -30, width: 250}}  type="primary" htmlType="submit" loading={loading} >
          Login
        </Button>
      </Form.Item>
    </Form>
    </Card>
  );
};

export default LoginPage;
