import React from 'react';
import { Result, Card } from 'antd';

const FailurePage = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -40, opacity: 0.85 }}>
      <Card style={{ width: '90%', opacity: 0.85, overflowY: 'auto', maxHeight: 'calc(70vh - 60px)' }}>
        <Result
          status="error"
          title="Falha no Pagamento"
          subTitle="Houve um erro ao processar o seu pagamento. Por favor, tente novamente."
          extra={[
            // Aqui você pode adicionar botões de ação, como "Tentar novamente"
          ]}
        />
      </Card>
    </div>
  );
};

export default FailurePage;
