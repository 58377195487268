import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../contexts/CartContext';
import {  Button, Tag, Input, message, Card, Switch, Drawer, Table, Modal, Select, Form, Badge, Divider } from 'antd';
import { db } from '../firebaseConfig'; // Importe seu db do Firebase
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDown, faArrowsLeftRight, faBox, faMaximize, faHashtag } from '@fortawesome/free-solid-svg-icons';

message.config({
  top: window.innerHeight - 150, // Posiciona a mensagem a 100px do rodapé
});

const CartPage = () => {
  const context = useContext(CartContext);
  const [coupons, setCoupons] = useState([]); // Para armazenar os cupons
  const [appliedCoupon, setAppliedCoupon] = useState(''); // Inicializado como string vazia
  const [discount, setDiscount] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const { Option } = Select;
const accessToken = 'APP_USR-5488522919392313-100622-85b87bb9cd9874bddeb50807d08e5f12-691306086'; // Access Token
  const [selectedSizes, setSelectedSizes] = useState({}); // Armazena os tamanhos selecionados
  const [isHalfPayment, setIsHalfPayment] = useState(false);
  const [isHalfPaymentBixo, setIsHalfPaymentBixo] = useState(false);
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    productName: '',
    address: '',
    houseNumber: '',
    complement: '',
    cep: '',
    phone: ''
  });


  const handleSwitchChange = (checked) => {
    setIsHalfPayment(checked);
  };

  const handleSwitchBixoChange = (checked) => {
    setIsHalfPaymentBixo(checked);
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const couponCollection = collection(db, 'coupons');
        const couponSnapshot = await getDocs(couponCollection);
        const couponsData = couponSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Cupons carregados:', couponsData); // Adicionado log para verificação
        setCoupons(couponsData);
      } catch (error) {
        console.error('Erro ao buscar cupons:', error);
      }
    };

    fetchCoupons();
  }, []);

  if (!context) {
    return <div>Erro: Cart Context não está disponível.</div>;
  }
// eslint-disable-next-line
  const { cart, removeFromCart, clearCart } = context; // Adicione a função clearCart aqui
  const cartItems = cart || [];

  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const discountAmount = (totalPrice * discount) / 100;
  const totalPriceWithDiscount = totalPrice - discountAmount;


  const applyCoupon = () => {
    const coupon = coupons.find(c => c.code.toLowerCase() === appliedCoupon.toLowerCase());
    console.log('Cupom encontrado:', coupon); // Verifica se o cupom foi encontrado
    
    if (coupon) {
      setDiscount(parseFloat(coupon.discount)); // Garantindo que o desconto seja um número
      message.success(`Cupom aplicado: ${coupon.code} (${coupon.discount}%)`);
    } else {
      message.error('Cupom inválido!');
      setDiscount(0); // Reinicia o desconto se o cupom for inválido
    }
  };

  
  const showDrawer = () => {
    setDrawerVisible(true);
  };
  
  const onClose = () => {
    setDrawerVisible(false);
  };


  const handleCheckout = async () => {

    const requiredFields = {
      firstName: 'Nome',
      lastName: 'Sobrenome',
      productName: 'Nome no Produto',
      address: 'Endereço',
      houseNumber: 'Número da casa/apartamento',
      phone: 'Telefone'
    };
  
    // Verifica quais campos estão faltando
    const missingFields = Object.keys(requiredFields).filter((field) => !formData[field]);
  
    if (missingFields.length > 0) {
      // Monta uma mensagem indicando os campos que não foram preenchidos
      const missingFieldsMessage = missingFields.map((field) => requiredFields[field]).join(', ');
      message.error(`Os seguintes campos são obrigatórios e não foram preenchidos: ${missingFieldsMessage}`);
      return; // Interrompe a função se houver campos faltando
    }

    const paymentAmount = isHalfPayment ? totalPriceWithDiscount / 2 : totalPriceWithDiscount;
    const uid = Math.random().toString(36).substr(2, 9); // Gera um UID aleatório

    localStorage.setItem('uid', uid); 

    let paymentCondition = "NAO_PAGO"; 

    // Lógica de pagamento com Mercado Pago
    const preference = {
      items: [
        {
          title: "Os Perdidão",
          unit_price: paymentAmount,
          quantity: 1,
        },
      ],
      back_urls: {
        success: 'https://osperdidao.shop/success',
        failure: 'https://osperdidao.shop/failure',
      },
      auto_return: 'approved',
    };

    try {
      const response = await fetch('https://api.mercadopago.com/checkout/preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(preference),
      });

      const data = await response.json();
      console.log(data);

      if (data.init_point) {
        window.location.href = data.init_point;
      } else {
        console.error('ID da preferência não encontrado:', data);
      }

    } catch (error) {
      console.error('Erro ao criar a preferência:', error);
      await saveClientData(uid, paymentCondition);
      return; // Sai da função se ocorrer erro
    }

    await saveClientData(uid, paymentCondition);
  };

  const saveClientData = async (uid, paymentCondition) => {
    const cartData = cartItems.map((item) => ({
      name: item.name,
      quantity: item.quantity,
      selectedSize: selectedSizes[item.type] || "Não selecionado", // Seleciona o tamanho ou exibe uma mensagem padrão
    }));
  
    const clientData = {
      ...formData,
      paymentCondition,
      isHalfPayment,
      isHalfPaymentBixo,
      totalPriceWithDiscount,
      amountPaid: isHalfPayment ? totalPriceWithDiscount / 2 : totalPriceWithDiscount,
      purchases: cartData // Adiciona os dados do carrinho ao cliente
    };
  
    try {
      await setDoc(doc(db, 'clients', uid), clientData);
      console.log('Dados salvos com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar os dados no Firebase:', error);
    }
  };
  
 
  const handleClearCart = () => {
    // Limpa todos os itens do carrinho

      cartItems.forEach(item => removeFromCart(item.id));
      message.success('Carrinho limpo com sucesso!');
   
  };

  const dataSource = [
    {
      key: '1',
      tamanho: 'P',
      largura: 55,
      comprimento: 71,
    },
    {
      key: '2',
      tamanho: 'M',
      largura: 58,
      comprimento: 74,
    },
    {
      key: '3',
      tamanho: 'G',
      largura: 61,
      comprimento: 77,
    },
    {
      key: '4',
      tamanho: 'GG',
      largura: 64,
      comprimento: 81,
    },
    {
      key: '5',
      tamanho: 'XGG',
      largura: 67,
      comprimento: 84,
    },
  ];

  const columns = [
    {
      title: 'Camiseta',
      dataIndex: 'tamanho',
      key: 'tamanho',
      align: 'center'
    },
    {
      title:  <>
      <FontAwesomeIcon icon={faArrowsLeftRight} />
    </>,
      dataIndex: 'largura',
      key: 'largura',
      align: 'center'
    },
    {
      title:  <>
      <FontAwesomeIcon icon={faArrowsUpDown} />
    </>,
      dataIndex: 'comprimento',
      key: 'comprimento',
      align: 'center'
    },
  ];


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const dataSourceBaby = [
    {
      key: '1',
      tamanho: 'P',
      cintura: 36,
      comprimento: 55,
    },
    {
      key: '2',
      tamanho: 'M',
      cintura: 37.5,
      comprimento: 57,
    },
    {
      key: '3',
      tamanho: 'G',
      cintura: 39,
      comprimento: 59,
    },
    {
      key: '4',
      tamanho: 'GG',
      cintura: 42,
      comprimento: 62,
    },
    {
      key: '5',
      tamanho: 'XGG',
      cintura: 45,
      comprimento: 64,
    },
  ];
  
  const columnsBaby = [
    {
      title: 'Baby Look',
      dataIndex: 'tamanho',
      key: 'tamanho',
      align: 'center'
    },
    {
      title:  <>
      <FontAwesomeIcon icon={faArrowsLeftRight} />
    </>,
      dataIndex: 'cintura',
      key: 'cintura',
      align: 'center'
    },
    {
      title:  <>
      <FontAwesomeIcon icon={faArrowsUpDown} />
    </>,
      dataIndex: 'comprimento',
      key: 'comprimento',
      align: 'center'
    },
  ];
  
  const dataSourceDoll = [
    {
      key: '1',
      tamanho: 'PP',
      cintura: '23-40',
      comprimento: 29,
    },
    {
      key: '2',
      tamanho: 'P',
      cintura: '25-42',
      comprimento: 31,
    },
    {
      key: '3',
      tamanho: 'M',
      cintura: '27-44',
      comprimento: 32,
    },
    {
      key: '4',
      tamanho: 'G',
      cintura: '30-49',
      comprimento: 34,
    },
    {
      key: '5',
      tamanho: 'GG',
      cintura: '32-51',
      comprimento: 36,
    },
    {
      key: '6',
      tamanho: 'XGG',
      cintura: '34-53',
      comprimento: 38,
    },
  ];
  
  const columnsDoll = [
    {
      title: 'Doll',
      dataIndex: 'tamanho',
      key: 'tamanho',
      align: 'center',
    },
    {
      title: (
        <>
          <FontAwesomeIcon icon={faArrowsLeftRight} />
        </>
      ),
      dataIndex: 'cintura',
      key: 'cintura',
      align: 'center',
    },
    {
      title: (
        <>
          <FontAwesomeIcon icon={faArrowsUpDown} />
        </>
      ),
      dataIndex: 'comprimento',
      key: 'comprimento',
      align: 'center',
    },
  ];

  const dataSourceSamba = [
    {
      key: '1',
      tamanho: 'PP',
      cintura: '31-44',
      comprimento: 35,
    },
    {
      key: '2',
      tamanho: 'P',
      cintura: '32-46',
      comprimento: 37,
    },
    {
      key: '3',
      tamanho: 'M',
      cintura: '34-48',
      comprimento: 39,
    },
    {
      key: '4',
      tamanho: 'G',
      cintura: '37-53',
      comprimento: 41,
    },
    {
      key: '5',
      tamanho: 'GG',
      cintura: '38-55',
      comprimento: 43,
    },
    {
      key: '6',
      tamanho: 'XGG',
      cintura: '39-57',
      comprimento: 45,
    },
  ];
  
  const columnsSamba = [
    {
      title: 'Samba',
      dataIndex: 'tamanho',
      key: 'tamanho',
      align: 'center',
    },
    {
      title: (
        <>
          <FontAwesomeIcon icon={faArrowsLeftRight} />
        </>
      ),
      dataIndex: 'cintura',
      key: 'cintura',
      align: 'center',
    },
    {
      title: (
        <>
          <FontAwesomeIcon icon={faArrowsUpDown} />
        </>
      ),
      dataIndex: 'comprimento',
      key: 'comprimento',
      align: 'center',
    },
  ];
  
  

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

// Função para controlar a mudança de tamanho selecionado
const handleSizeChange = (value, itemType) => {
  setSelectedSizes((prevSelectedSizes) => ({
    ...prevSelectedSizes,
    [itemType]: value, // Atualiza o tamanho selecionado para o item correspondente
  }));
};

// Função que verifica se todos os itens possuem um tamanho selecionado
const allSizesSelected = () => {
  return cartItems.every(item => selectedSizes[item.type] && selectedSizes[item.type] !== 'Selecione');
};

// Modificar a função onClick do botão para verificar os tamanhos antes de abrir o drawer
const handleShowDrawer = () => {
  if (allSizesSelected()) {
    showDrawer(); // Só abre o drawer se todos os tamanhos estiverem selecionados
  } else {
    // Exibir uma mensagem ou realizar outra ação caso um tamanho não esteja selecionado
    message.error("Por favor, selecione o tamanho para todos os produtos antes de prosseguir com a compra.");
  }
};

  const columnsBuy = [
    {
      title: <FontAwesomeIcon icon={faBox} />,
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text) => <p>{text}</p>,
    },
    {
      title: <FontAwesomeIcon icon={faHashtag} />,
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      render: (text) => <p>{text}</p>,
    },
    {
      title: <FontAwesomeIcon icon={faMaximize} />,
      key: 'action',
      align: 'center',
      render: (item) => {
        console.log("Rendering item Type:", item.type); // Log do tipo do item sendo renderizado

        // Definindo as opções de acordo com o tipo do item
        let sizeOptions = [];
        if (item.type === 'Camiseta' || item.type === 'BabyLook') {
          sizeOptions = ['P', 'M', 'G', 'GG', 'XGG'];
        } else if (item.type === 'Doll' || item.type === 'Samba') {
          sizeOptions = ['PP', 'P', 'M', 'G', 'GG', 'XGG'];
        }

        // Renderizando o select com base nas opções definidas
        return sizeOptions.length > 0 ? (
          <Select
            defaultValue="Selecione"
            value={selectedSizes[item.type] || "Selecione"} // Use o valor selecionado ou "Selecione"
            onChange={(value) => handleSizeChange(value, item.type)} // Passa o type em vez do id
            style={{ width: 110 }}
          >
            {sizeOptions.map((size) => (
              <Option key={size} value={size}>
                {size}
              </Option>
            ))}
          </Select>
        ) : (
          <span>—</span> // Para Convite ou outros tipos, sem select
        );
      },
    },
  ];

  const dataSourceBuy = cartItems.map((item, index) => ({
    key: `${item.type}-${index}`, // Concatena type e index para garantir unicidade
    name: item.name,
    quantity: item.quantity,
    type: item.type,
    description: item.description,
  }));
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -40, opacity: 0.85}}>
      
      <Card style={{ width: '90%', opacity: 0.85, overflowY: 'auto', maxHeight: 'calc(70vh - 60px)' }}>
      <Card style={{ marginBottom: 15  }}>
  <div style={{ marginTop: -30 }}>
    <h2>Carrinho</h2>
  </div>
  <Divider style={{marginTop: -10, marginBottom: 40}}></Divider>
  {cartItems.map((item) => (
    <div key={item.id} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 15, marginTop: -15 }}>
      
      {/* Imagem e botão Remover */}
      <div style={{ position: 'relative', display: 'inline-block', marginRight: 15, textAlign: 'center' }}>
        <Badge
          count={item.quantity}
          style={{
            backgroundColor: '#ffffff',
            color: '#ff0000',
            border: '1px solid #ff0000', 
            fontSize: '14px',
            position: 'absolute',
            top: -100,  // Ajusta a distância do topo
            left: 105,  // Ajusta a distância da esquerda
            borderRadius: '8px',
          }}
        />

        <img
          src={item.imageUrl}
          alt={item.name}
          style={{ width: 120, height: 100, objectFit: 'cover', borderRadius: 8 }}
        />
        <div style={{ marginTop: 8 }}>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
  <Button  danger style={{left: 5}}
    onClick={() => context.updateItemQuantity(item.id, item.quantity - 1)} 
    disabled={item.quantity <= 1} // Desabilita se a quantidade for 1 ou menor
  >
    -
  </Button>
  <Input
    variant="borderless" 
    value={item.quantity}
    readOnly
    onChange={(e) => {
      const value = parseInt(e.target.value);
      if (value >= 1) {
        context.updateItemQuantity(item.id, value);
      }
    }}
    style={{ width: 40, textAlign: 'center', margin: '0 10px' }}
  />
  <Button danger style={{right: 5}}
    onClick={() => context.updateItemQuantity(item.id, item.quantity + 1)}
  >
    +
  </Button>
</div>

        </div>
        {/* Botão Remover abaixo da imagem */}
        <div style={{ marginTop: 8 }}>
          <Button
            type="primary"
            danger
            onClick={() => removeFromCart(item.id)}
            style={{width: "120px"}}
          >
            Remover
          </Button>
          <br></br>
          <br></br>
        </div>
      </div>

      <div style={{ flexGrow: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0, marginTop: -10 }}>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{item.name}</span>
        </div>
        <div 
    style={{ 
      marginTop: 8, 
      marginRight: 20, 
      marginBottom: 8, 
      fontSize: '12px', 
      fontWeight: 'bold', 
      maxWidth: '100%', 
      whiteSpace: 'normal',  // Permite que o texto quebre em palavras completas
      wordWrap: 'break-word',  // Quebra as palavras grandes para caberem
      lineHeight: '1.2',  // Ajusta o espaçamento entre linhas para melhor leitura
    }}
  >
    {item.description}
  </div>
      </div>
    </div>
  ))}
</Card>

<Button type="primary" danger style={{ width: '100%', marginBottom: 20 }} onClick={showModal}>
  Exibir Tabela de Tamanho
</Button>
<Table  style={{ width: '100%', marginBottom: 20, overflowX: 'auto', marginTop: 0 }} dataSource={dataSourceBuy} columns={columnsBuy} pagination={false} />


        <Input
          value={appliedCoupon}
          onChange={(e) => setAppliedCoupon(e.target.value)} // Atualiza o valor do cupom
          placeholder="Código do cupom"
          variant="borderless" 
          style={{width: "100%", marginBottom: 15}}
        />
        <Button onClick={applyCoupon} type="primary" style={{ width: '100%' }}>Aplicar Cupom</Button>


      <h3>Valor da Compra: R${totalPrice.toFixed(2)}</h3>
      <h3>Desconto: -R${discountAmount.toFixed(2)} ({discount}%)</h3>
      <h3>Valor com Desconto: R${totalPriceWithDiscount.toFixed(2)}</h3>



      {/* Botão para limpar o carrinho */}
      <Button onClick={handleClearCart} type="primary" danger style={{ width: '100%', marginBottom: 15 }}>
        Limpar Carrinho
      </Button>
      <Drawer
  title="Finalizar compra" // Título usando a propriedade title do Drawer
  placement="right"
  onClose={onClose}
  visible={drawerVisible}
  width={400}
  style={{ opacity: 0.95 }}
  footer={
    <div style={{ textAlign: 'center' }}>
      <Button danger type="primary" onClick={handleCheckout} style={{ width: '100%' }}>Confirmar Compra</Button>
    </div>
  }
>
<div style={{marginTop: -30}}>
      <Form layout="vertical" style={{ marginBottom: '16px' }}>
      <Form.Item>
        <Input
          name="firstName"
          variant="borderless"
          style={{ width: '100%', marginBottom: 0, marginTop: 10 }}
          placeholder="Digite seu nome"
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item>
        <Input
          name="lastName"
          variant="borderless"
          style={{ width: '100%', marginBottom: 0, top: -15 }}
          placeholder="Digite seu sobrenome"
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item>
        <Input
          name="productName"
          variant="borderless"
          style={{ width: '100%', marginBottom: 0, top: -30 }}
          placeholder="Digite o nome que aparecerá no produto"
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item>
        <Input
          name="address"
          variant="borderless"
          style={{ width: '100%', marginBottom: 0, top: -45 }}
          placeholder="Digite seu endereço"
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item>
        <Input
          name="houseNumber"
          variant="borderless"
          style={{ width: '100%', marginBottom: 0, top: -60 }}
          placeholder="Número da casa/apartamento"
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item>
        <Input
          name="complement"
          variant="borderless"
          style={{ width: '100%', marginBottom: 0, top: -75 }}
          placeholder="Complemento (opcional)"
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item>
        <Input
          name="cep"
          variant="borderless"
          style={{ width: '100%', marginBottom: 0, top: -90 }}
          placeholder="Digite seu CEP"
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item>
        <Input
          name="phone"
          variant="borderless"
          style={{ width: '100%', marginBottom: 0, top: -105 }}
          placeholder="Digite seu Telefone"
          onChange={handleInputChange}
        />
      </Form.Item>
      <Card style={{top: -115}}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
  <h3 style={{ color: "#fff", marginRight: '10px' }}>Pagar metade do valor agora e metade quando entrarmos em contato</h3>
  <Switch defaultChecked={isHalfPayment} onChange={handleSwitchChange} />
</div>
<div style={{ display: 'flex', alignItems: 'center' }}>
  <h3 style={{ color: "#fff", marginRight: '10px' }}>Frete bixo (apenas em Itajuba) + R$3,00</h3>
  <Switch defaultChecked={isHalfPayment} onChange={handleSwitchBixoChange} />
</div>


      </Card>
      <div style={{ display: 'flex', alignItems: 'center' }}>
 
      <h3 style={{color: "#fff"}}>
<Tag style={{width: "100%", height: '40px', fontSize: '20px', alignItems: "center", justifyContent: 'center', display: "flex", top: -120}} color="green">Valor: R${(() => {
    let price = totalPriceWithDiscount;

    // Se `isHalfPayment` estiver true, divide o valor por 2
    if (isHalfPayment) {
      price /= 2;
    }

    // Se `isHalfPaymentBixo` estiver true, aumenta 3 no valor após qualquer divisão
    if (isHalfPaymentBixo) {
      price += 3;
    }

    return price.toFixed(2);
  })()}
</Tag>

<Tag style={{ 
  height: '95px',               // Ajusta a altura automaticamente ao conteúdo
  fontSize: '20px', 
  alignItems: "center", 
  justifyContent: 'center', 
  display: "flex", 
  textAlign: 'center',           // Centraliza o texto
  whiteSpace: 'normal',          // Permite quebras de linha
  width: '100%',                  // Define uma largura para limitar o texto
  top: -110
 
}} color="red">
  Após efetuar a compra no site do Mercado Pago, clique em "Voltar para o site" para confirmar sua compra  
</Tag>


</h3>
</div>
      </Form>
      </div>
</Drawer>


<Modal
  visible={modalVisible}
  onOk={handleOk}
  width={600}
  closeIcon={null}
  footer={[
    <Button key="ok" danger type="primary" onClick={handleOk}>
     Fechar
    </Button>
  ]}
  style={{ marginTop: -50, opacity: 0.95 }}
>
      <Card style={{ overflowY: 'auto', maxHeight: 'calc(70vh - 60px)' }}>
<Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          style={{ width: '100%', marginBottom: 20, overflowX: 'auto' }}
        />

<Table
          dataSource={dataSourceBaby}
          columns={columnsBaby}
          pagination={false}
          style={{ width: '100%', marginBottom: 20, overflowX: 'auto' }}
        />

<Table
          dataSource={dataSourceDoll}
          columns={columnsDoll}
          pagination={false}
          style={{ width: '100%', overflowX: 'auto', marginBottom: 20 }}
        />


<Table
          dataSource={dataSourceSamba}
          columns={columnsSamba}
          pagination={false}
          style={{ width: '100%', overflowX: 'auto' }}
        />


</Card>
      </Modal>
<Button type="primary" style={{ width: '100%' }} onClick={handleShowDrawer}>
  Finalizar Compra
</Button>

      </Card>
      </div>
  );
};

export default CartPage;
