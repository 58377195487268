// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth'; // Importando o Firebase Auth

const firebaseConfig = {
    apiKey: "AIzaSyBgOkgOtl-KoYogcEtDnsWrgd3Qrrc0w5k",
    authDomain: "perdidaoshop.firebaseapp.com",
    projectId: "perdidaoshop",
    storageBucket: "perdidaoshop.appspot.com",
    messagingSenderId: "1055235713452",
    appId: "1:1055235713452:web:12a9f4472b32705bc7f209"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app); // Inicializando o Firebase Auth

export { db, storage, auth }; // Exportando também o auth
