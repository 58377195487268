import React, { useState, useEffect } from 'react';
import { storage, db, auth } from '../firebaseConfig'; // Importa o auth do Firebase
import { onAuthStateChanged, signOut } from 'firebase/auth'; // Importa o método para observar o estado da autenticação
import { addDoc, collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Button, Input, Form, Upload, message, List, Card, Select, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import LoginPage from './LoginPage'; // Importa a página de login


const AdminPage = () => {
  const [productForm] = Form.useForm();
  const [couponForm] = Form.useForm();
  const [imageFile, setImageFile] = useState(null);
  const [products, setProducts] = useState([]); // Estado para armazenar produtos
  const [coupons, setCoupons] = useState([]); // Estado para armazenar cupons
  const [uploadProgress, setUploadProgress] = useState(0); 
  const { Option } = Select;
  const [user, setUser] = useState(null); // Estado para armazenar o usuário
 
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Usuário está logado
        fetchProducts(); // Busca produtos quando o usuário está logado
        fetchCoupons(); // Busca cupons quando o usuário está logado
      } else {
        setUser(null); // Usuário não está logado
      }
    });

    return () => unsubscribe(); // Limpa o listener
  }, []);

  const handleLogin = () => {
    fetchProducts();
    fetchCoupons();
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      message.success('Você foi deslogado com sucesso!');
    } catch (error) {
      message.error('Erro ao deslogar. Tente novamente.');
      console.error(error);
    }
  };


  // Função para adicionar um novo cupom
  const onFinishCoupon = async (values) => {
    try {
      await addDoc(collection(db, 'coupons'), {
        code: values.code,
        discount: values.discount,
      });

      message.success('Cupom adicionado com sucesso!');
      couponForm.resetFields(); // Reseta apenas o formulário de cupons
      fetchCoupons(); // Atualiza a lista de cupons após adicionar
    } catch (error) {
      message.error('Erro ao adicionar o cupom. Tente novamente.');
      console.error(error);
    }
  };

  // Função para adicionar um novo produto
  const onFinishProduct = async (values) => {
    if (!imageFile) {
      message.error('Por favor, selecione uma imagem.');
      return;
    }

    const storageRef = ref(storage, `products/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    // Monitorar o progresso do upload
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        message.error('Erro ao fazer upload da imagem.');
        console.error(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          await addDoc(collection(db, 'products'), {
            ...values,
            imageUrl: downloadURL,
          });

          message.success('Produto adicionado com sucesso!');
          productForm.resetFields(); // Reseta apenas o formulário de produtos
          setImageFile(null);
          setUploadProgress(0); // Reseta o progresso
          fetchProducts(); // Atualiza a lista de produtos após adicionar
        } catch (error) {
          message.error('Erro ao adicionar o produto. Tente novamente.');
          console.error(error);
        }
      }
    );
  };

  // Função para buscar produtos
  const fetchProducts = async () => {
    try {
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      const productsData = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsData);
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    }
  };

  // Manipulador para o upload de imagem
  const handleImageUpload = (file) => {
    setImageFile(file);
    return false; // Para impedir o upload automático do Ant Design
  };

  // useEffect para buscar produtos ao montar o componente
  useEffect(() => {
    fetchProducts();
  }, []);

  // Função para buscar cupons
  const fetchCoupons = async () => {
    try {
      const couponCollection = collection(db, 'coupons');
      const couponSnapshot = await getDocs(couponCollection);
      const couponsData = couponSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCoupons(couponsData);
    } catch (error) {
      console.error('Erro ao buscar cupons:', error);
    }
  };

  // Função para excluir um produto
  const deleteProduct = async (id) => {
    try {
      await deleteDoc(doc(db, 'products', id));
      message.success('Produto excluído com sucesso!');
      fetchProducts(); // Atualiza a lista após exclusão
    } catch (error) {
      message.error('Erro ao excluir o produto. Tente novamente.');
      console.error(error);
    }
  };

  // Função para excluir um cupom
  const deleteCoupon = async (id) => {
    try {
      await deleteDoc(doc(db, 'coupons', id));
      message.success('Cupom excluído com sucesso!');
      fetchCoupons(); // Atualiza a lista após exclusão
    } catch (error) {
      message.error('Erro ao excluir o cupom. Tente novamente.');
      console.error(error);
    }
  };



  // useEffect para buscar produtos e cupons ao montar o componente
  useEffect(() => {
    fetchProducts();
    fetchCoupons();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -40, opacity: 0.85 }}>
        {user ? (
      <Card style={{ width: '90%', opacity: 0.85, overflowY: 'auto', maxHeight: 'calc(70vh - 60px)' }}>
          <Button type="primary" danger onClick={handleLogout} style={{ marginTop: 16, width: "100%" }}>
            Deslogar
          </Button>
      <h2>Adicionar Novo Produto</h2>
        <Form form={productForm} onFinish={onFinishProduct} layout="vertical">
          <Form.Item name="name" rules={[{ required: true }]}>
            <Input placeholder="Nome do Produto" variant="borderless" style={{ width: "100%", marginBottom: 0 }} />
          </Form.Item>
          <Form.Item name="price" rules={[{ required: true }]}>
            <Input placeholder="Valor do Produto" variant="borderless" style={{ width: "100%", marginBottom: 0, top: -15 }} type="number" />
          </Form.Item>
          <Form.Item name="description" rules={[{ required: true }]}>
            <Input placeholder="Descrição do Produto" variant="borderless" style={{ width: "100%", marginBottom: 0, top: -30 }} />
          </Form.Item>
          <Form.Item name="type" rules={[{ required: true }]} >
            <Select placeholder="Selecione o Tipo de Produto" defaultValue="Selecione" style={{ width: "100%", marginBottom: 0, top: -45 }}>
              <Option value="Camiseta">Camiseta</Option>
              <Option value="BabyLook">BabyLook</Option>
              <Option value="Doll">Doll</Option>
              <Option value="Samba">Samba</Option>
              <Option value="Convite">Convite</Option>
            </Select>
          </Form.Item>
          <Form.Item rules={[{ required: true }]}>
            <Upload beforeUpload={handleImageUpload} showUploadList={false}>
              <Button type="primary" style={{ width: "100%", marginBottom: 0, top: -60 }} icon={<UploadOutlined />}>Selecionar Imagem</Button>
            </Upload>
          </Form.Item>
          
          {/* Barra de Progresso */}
          {uploadProgress > 0 && (
            <Progress percent={Math.round(uploadProgress)} style={{ marginBottom: 16, top: -75 }} />
          )}
          
          <Form.Item>
            <Button type="primary" style={{ width: "100%", marginBottom: 0, top: -75 }} htmlType="submit">Adicionar Produto</Button>
          </Form.Item>
        </Form>


        <h2 style={{ marginTop: -60 }}>Criar Novo Cupom</h2>
        <Form form={couponForm} onFinish={onFinishCoupon} layout="vertical">
          <Form.Item name="code" rules={[{ required: true }]}>
            <Input placeholder="Código do Cupom" variant="borderless" style={{ width: "100%", marginBottom: 0 }} />
          </Form.Item>
          <Form.Item name="discount" rules={[{ required: true }]}>
            <Input placeholder="Valor do Desconto" variant="borderless" style={{ width: "100%", marginBottom: 0, top: -15 }} type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" style={{ width: "100%", marginBottom: 0, top: -30 }} htmlType="submit">Adicionar Cupom</Button>
          </Form.Item>
        </Form>

        <h2 style={{ marginTop: -30 }}>Produtos Adicionados</h2>
        <List
          itemLayout="horizontal"
          dataSource={products}
          renderItem={item => (
            <List.Item
              actions={[<Button type="primary" danger onClick={() => deleteProduct(item.id)}>Excluir</Button>]}
            >
              <List.Item.Meta
                title={item.name}
                description={`Preço: R$${item.price}`}
              />
            </List.Item>
          )}
        />

        <h2 style={{ marginTop: '40px' }}>Cupons Ativos</h2>
        <List
          itemLayout="horizontal"
          dataSource={coupons}
          renderItem={item => (
            <List.Item
              actions={[<Button type="primary" danger onClick={() => deleteCoupon(item.id)}>Excluir</Button>]}
            >
              <List.Item.Meta
                title={item.code}
                description={`Desconto: ${item.discount}%`}
              />
            </List.Item>
          )}
        />
      </Card>
        ) : (
          <LoginPage onLogin={handleLogin} /> // Mostra a página de login se não houver usuário logado
        )}
    </div>
  );
};

export default AdminPage;
