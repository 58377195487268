import React, { useEffect, useState, useContext } from 'react';
import { Card, Button, Image, message } from 'antd';
import Slider from 'react-slick';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { CartContext } from '../contexts/CartContext';

// Importando os estilos do slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Importando Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Importando os ícones

const HomePage = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productsCollection);
      const productList = productSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (productList.length > 0) {
        setProducts(productList);
      }
    };

    fetchProducts();
  }, []);

  // Componente de seta anterior
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div 
        className={className}
        onClick={onClick}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', zIndex: 1 }} // Adicionando estilo
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color="#fff"
          size="1x"
        />
      </div>
    );
  };

  // Componente de seta próxima
  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div 
        className={className}
        onClick={onClick}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', zIndex: 1 }} // Adicionando estilo
      >
        <FontAwesomeIcon
          icon={faArrowRight}
          color="#fff"
          size="1x"
        />
      </div>
    );
  };

  const handleAddToCart = (product) => {
    addToCart(product); // Adiciona o produto ao carrinho
    message.success(`${product.name} adicionado ao carrinho!`); // Exibe mensagem de sucesso
  };

  // Configurações do slider
  const settings = {
    dots: true,
    infinite: products.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 0.85, marginTop: -40 }}>
      <div style={{ width: '70%', maxWidth: '600px' }}>
        {products.length > 0 ? (
          <Slider {...settings}>
            {products.map((product) => (
              <div key={product.id}>
                <Card 
                  style={{ width: "100%", margin: "0 auto" }}
                  title={product.name}
                  extra={`R$ ${product.price}`}
                  cover={
                    <Image
                      alt={product.name}
                      src={product.imageUrl}
                      style={{ height: 300, objectFit: 'cover' }}
                    />
                  }
                >
                  <p style={{marginTop: -15}}>{product.description}</p>
                  <Button 
                    type="primary" 
                    style={{ width: '100%' }} 
                    onClick={() => handleAddToCart(product)} // Usando a função handleAddToCart
                  >
                    Adicionar ao Carrinho
                  </Button>
                </Card>
              </div>
            ))}
          </Slider>
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <p>Sem produtos na loja.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
