import React, { useEffect } from 'react';
import { Result, Card } from 'antd';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Certifique-se de que o caminho está correto

const SuccessPage = () => {

  const uid = localStorage.getItem('uid'); // Pega o UID do localStorage

  useEffect(() => {
    const moveDataToSuccessCollection = async () => {
      try {
        if (uid) {
          // Referência ao documento original
          const docRef = doc(db, 'clients', uid);
          
          // Obtém o documento original
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const clientData = docSnap.data(); // Obtém os dados do cliente
            
            // Adiciona os dados na coleção 'Success' com o mesmo UID
            await setDoc(doc(db, 'Success', uid), { 
              ...clientData, 
              paymentCondition: 'REALIZADO' // Atualiza a condição de pagamento
            });
            
            // Opcional: Excluir o documento da coleção original
            await deleteDoc(docRef);
            
            console.log('Dados movidos para a coleção Success com sucesso.');
          } else {
            console.error('Documento não encontrado.');
          }
        }
      } catch (error) {
        console.error('Erro ao mover dados para a coleção Success:', error);
      }
    };

    moveDataToSuccessCollection();
  }, [uid]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -40, opacity: 0.85 }}>
      <Card style={{ width: '90%', opacity: 0.85, overflowY: 'auto', maxHeight: 'calc(70vh - 60px)' }}>
        <Result
          status="success"
          title="Compra Realizada com Sucesso!"
          extra={[
            <h3 style={{ color: "#ffffff" }}>Um morador entrará em contato para confirmar dados do pedido.</h3>
          ]}
        />
      </Card>
    </div>
  );
};

export default SuccessPage;
